<template>
  <div class="tips">
    <div class="flex-row-center content">
      <el-image class="tips-img" :src="images[0]"></el-image>
      <div class="tips-text">{{ $t("tipsMessage.noPower") }}</div>
    </div>
  </div>
</template>

<script>
export default {
name: "tips-no-powers",
  data() {
    return {
      images: [require(`../../static/img/tips-face.png`)]
    };
  }
};
</script>

<style scoped lang="scss">
.tips {
  .content {
    text-align: center;
    display: -webkit-flex;
    flex-direction: column;
    height: calc(100vh - 100px);
    min-height: 500px;
    .tips-img {
      width: 80px;
      height: 80px;
      margin-bottom: 20px;
    }
    .tips-text {
      font-size: 30px;
      font-family: "PingFangSC-Regular, PingFang SC";
      font-weight: 400;
      color: #000000;
      line-height: 42px;
      margin-bottom: 20px;
    }
  }
}
</style>